import React from "react"

const nested = () => {
  return (
    <div>
      <h2>less/in-less</h2>
    </div>
  )
}

export default nested
